<template>
  <router-view />
  <button
    id="RFID_modal_btn"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#kt_modal_RFID"
  ></button>
  <ModalACR122Actions :cardNumber="cardData" />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useRoute } from "vue-router";
import useSocket from "@/composables/useSocket";
import ModalACR122Actions from "./components/utility/ModalACR122Actions.vue";
export default defineComponent({
  name: "app",
  components: {
    ModalACR122Actions,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { cardData } = useSocket();

    onMounted(async () => {
      const route = useRoute();

      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("myParam");

      await store.dispatch("getLookupsEventoAnno");
      // if (!urlParams.size) {
      //   await store.dispatch("getConfigurazioniEvento");
      // }

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      document.addEventListener("show.bs.dropdown", function () {
        const trs = document.querySelectorAll("tr");
        const dropdownButtons = document.querySelectorAll(
          ".bi-three-dots-vertical"
        );
        const dropdownMenus = document.querySelectorAll(".dropdown-menu");

        let includesTallElement = false;
        trs.forEach((element) => {
          if (element.offsetHeight > 100) {
            includesTallElement = true;
          }
        });

        trs.forEach((element, index) => {
          if (index != 0 && trs.length < 8) {
            dropdownMenus.forEach((dropdown, indexdd) => {
              dropdownButtons.forEach((dots, indexdots) => {
                const dotsPosition = dots.getBoundingClientRect();
                if (indexdd === index - 1 && indexdots === index - 1) {
                  const position = () => {
                    dropdown.classList.add("position-fixed");
                    const elementPosition = element.getBoundingClientRect();
                    var style = document.createElement("style");
                    style.type = "text/css";
                    // inset: unset!important;

                    style.innerHTML = `.dropdown-transform${index} { transform: translate3d(-82px, 38px, 0px)!important; inset: ${elementPosition.y}px 0px auto auto!important;  left:${elementPosition.x}px; top: ${elementPosition.y}px`;

                    document.getElementsByTagName("head")[0].appendChild(style);

                    dropdown.classList.add(`dropdown-transform${index}`);
                  };
                  document.addEventListener("scroll", position, true);
                  position();
                }
              });
            });
          }
        });
      });

      nextTick(() => {
        initializeComponents();
      });
    });
    return { cardData };
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>
