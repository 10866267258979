import {
  alertChoiceNoFunction,
  alertFailed,
  alertSuccess,
} from "@/functions/swAlert";

export async function scriviSuRFID(number, length, endpoint) {
  if (number.length !== length) {
    alertFailed("Il numero deve contenere esattamente 8 cifre.");
    return;
  }
  await alertChoiceNoFunction(
    "Sei sicuro di voler scrivere il tag con il numero <b>" + number + "</b>?"
  ).then(async (confirmed) => {
    if (confirmed) {
      try {
        const response = await fetch(`http://localhost:3001${endpoint}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ number: number }),
        });
        const data = await response.json();
        if (data.success) {
          alertSuccess(
            "Numero <b>" +
              number +
              "</b> scritto con successo sul braccialetto!"
          );
        } else {
          alertFailed("Errore: " + data.message);
        }
      } catch (error) {
        console.error("Errore di comunicazione con il middleware:", error);
        alertFailed("Errore di comunicazione con il middleware.");
      }
    }
  });
}
