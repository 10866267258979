import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalACR122Actions = _resolveComponent("ModalACR122Actions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _cache[0] || (_cache[0] = _createElementVNode("button", {
      id: "RFID_modal_btn",
      class: "d-none",
      "data-bs-toggle": "modal",
      "data-bs-target": "#kt_modal_RFID"
    }, null, -1)),
    _createVNode(_component_ModalACR122Actions, { cardNumber: _ctx.cardData }, null, 8, ["cardNumber"])
  ], 64))
}