import moment from "moment";
export function format(date) {
  if (typeof date != "string") {
    const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    const month =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
}
export function formatDateITA(date) {
  return moment(new Date(date), "ddd MMM DD YYYY HH:mm:ss Z").format(
    "DD/MM/YYYY HH:mm"
  );
}
export function formatDateITANoTime(date) {
  return moment(new Date(date), "ddd MMM DD YYYY HH:mm:ss Z").format(
    "DD/MM/YYYY"
  );
}

export function useFormatDateYMD(dateString) {
  const day = dateString.substring(0, 2);
  const month = dateString.substring(3, 5);
  const year = dateString.substring(6, dateString.length);
  // expose managed state as return value
  return `${year}-${month}-${day}`;
}

export function verificaFormatoData(dataString) {
  var formatoData = /^\d{2}\/\d{2}\/\d{4}$/;
  if (formatoData.test(dataString)) {
    return useFormatDateYMD(dataString); // La stringa corrisponde al formato desiderato
  } else {
    return null; // La stringa non corrisponde al formato desiderato
  }
}

export const disabledDates = {
  to: moment().subtract(18, "years").toDate(), // Imposta la data massima a 18 anni fa
};

export const startTime = { hours: 0, minutes: 0 };

export const today = moment().format("YYYY-MM-DD");
export const dateToReset = moment("2100-01-01").format("YYYY-MM-DD");

export const isMaggiorenne = (date) => {
  const dataDiNascita = moment(date);
  const dataMinimaMaggiorenne = moment().subtract(18, "years").toDate();
  const isMaggiorenne = dataDiNascita.isBefore(dataMinimaMaggiorenne);
  return isMaggiorenne;
};
// EXAMPLE MOMENT JS
// const todayDate = moment().startOf("day");
// const YM = todayDate.format("YYYY-MM");
// const YESTERDAY = todayDate.clone().subtract(1, "day").format("YYYY-MM-DD");
// const TODAY = todayDate.format("YYYY-MM-DD");
// const TOMORROW = todayDate.clone().add(1, "day").format("YYYY-MM-DD ");
// const TODAYPLUSTWOHOURS = todayDate
//   .clone()
//   .add(2, "hours")
//   .format("YYYY-MM-DD HH:mm:ss");
