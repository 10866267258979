import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { registerGuard } from "./Guard";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/persone",
        name: "persone",
        component: () => import("@/views/PeopleList.vue"),
      },
      {
        path: "/sessioni",
        name: "sessioni",
        component: () => import("@/views/SessionsList.vue"),
      },
      {
        path: "/inviti",
        name: "inviti",
        component: () => import("@/views/InvitationsList.vue"),
      },
      {
        path: "/gruppi",
        name: "gruppi",
        component: () => import("@/views/GroupsList.vue"),
      },
      {
        path: "/invito/:id",
        name: "invito",
        component: () => import("@/views/SessioniUtente.vue"),
      },
      {
        path: "/tribune",
        name: "tribune",
        component: () => import("@/views/SeatsReservation.vue"),
      },
      {
        path: "/biglietteria",
        name: "biglietteria",
        component: () => import("@/views/TicketingList.vue"),
      },
      {
        path: "/storico-accrediti",
        name: "storico-accrediti",
        component: () => import("@/views/LogCrionet.vue"),
      },
      {
        path: "/comunicazioni-inviate-list",
        name: "comunicazioni-inviate",
        component: () => import("@/views/CommunicationList.vue")
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/LayoutNoMenu.vue"),
    children: [
      {
        path: "/sessioni-utente-new",
        name: "sessioni-utente",
        component: () => import("@/views/SessioniUtente.vue"),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/sessioni-utente",
        name: "sessioni-utente",
        component: () => import("@/views/SessioniUtente.vue"),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/inviti-consigliere",
        name: "inviti-consigliere",
        component: () => import("@/views/AddInvitationConsigliere.vue"),
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/PrivacyPolicy.vue"),
        meta: {
          noAuth: true,
        },
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/errors/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
router.onError((error) => {
  /*  console.log(
     "QUESTO PERMETTE DI INTERCETTARE ERRORI NEL CARICAMNTO DEI FILE JS. DA IMPLEMENTARE LA GESTIONE DI QUEST'ERRORE",
     error,
     error.name,
     error.message
   ); */
  window.location.reload();
});
registerGuard(router);
export default router;
