<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_RFID">
    <div class="modal-dialog">
      <loadingVue
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loadingVue>
      <Form
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="formValues"
        :validation-schema="schema"
        autocomplete="off"
      >
        <!--         v-slot="{ values }" -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modifica RFID</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_RFID_closex"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body" v-if="cardNumber" :key="cardNumber.number">
            <div class="card-body p-6">
              <div class="ps-5 pe-5 repeater-form">
                <div class="row pb-4">
                  Il Numero scritto sul braccialetto è: {{ cardNumber.number }}.
                  Tipologia di braccialetto {{ cardNumber.type }}
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Valora da inserire sul Bracciale</label
                    >
                    <Field name="rfid_number" class="form-control" />
                    <ErrorMessage name="rfid_number" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start">
            <div class="row w-100">
              <div class="d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  class="badge bg-light-danger rounded text-danger fs-6 me-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="kt_modal_RFID_close_button"
                >
                  Chiudi
                </button>
                <button
                  type="submit"
                  class="badge bg-light-success rounded text-success fs-6 me-2"
                >
                  Scrivi su RFID
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import moment from "moment";
import { format } from "@/functions/formatDate";

import { scriviSuRFID } from "@/functions/funzioniRFID";
export default {
  name: "add-persona-modal",
  components: {
    Field,
    ErrorMessage,
    Form,
  },
  props: {
    cardNumber: {
      type: Object,
    },
  },
  emits: ["getList"],
  setup(props, { emit }) {
    const numberLength = ref(22);
    const schema = computed(() => {
      return yup.object().shape({
        rfid_number: yup
          .string()
          .required("Il numero RFID è obbligatorio")
          .matches(/^[0-9]+$/, "Inserire solo numeri")
          .test(
            "len",
            `Inserire esattamente ${numberLength.value} caratteri`,
            (val) => val && val.length === numberLength.value
          ),
      });
    });

    const formValues = ref({
      rfid_number:
        props.cardNumber && props.cardNumber.number
          ? props.cardNumber.number
          : null,
    });
    watch(
      () => props.cardNumber,
      (val) => {
        formValues.value.rfid_number = val ? val.number : null;
        numberLength.value = val ? val.charmax : 8;
      },
      { deep: true }
    );

    const onSubmit = async (values, { resetForm }) => {
      // /api/write-tag-${numberLength.value}-right-aligned
      // per scrivere su card con offset di 5
      scriviSuRFID(
        values.rfid_number,
        numberLength.value,
        `/api/write-tag-${numberLength.value}`
      );
    };

    return {
      onSubmit,
      moment,
      format,
      formValues,
      schema,
      blob: process.env.VUE_APP_BLOB,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
</style>
