export enum API {
  // MENU
  SYSTEM_MENU_GET = "/v1/system/menu/get",
  //GENERICS
  LOOKUP_GET = "/v1/lookup/get",
  LOOKUP_PERSON_SEARCH = "v2/lookup/person/search",
  LOOKUP_GUEST_SEARCH = "/v1/lookup/guest/search",
  // GROUPS
  GROUPS_ADD = "/v1/groups/add",
  GROUPS_DEL = "/v1/groups/del",
  GROUPS_EDIT = "/v1/groups/edit",
  GROUPS_LIST = "/v1/groups/list",
  GROUPS_VIEW = "v1/groups/view",
  ANAGRAPHICS_PEOPLE_IMPORT = "/v1/anagraphics/people/import",
  ANAGRAPHICS_PEOPLE_IMPORT_ADD = "/v1/anagraphics/people/import/add",
  // PEOPLE
  ANAGRAPHICS_PEOPLE_ADD = "/v1/anagraphics/people/add",
  ANAGRAPHICS_PEOPLE_DEL = "/v1/anagraphics/people/del",
  ANAGRAPHICS_PEOPLE_EDIT = "/v1/anagraphics/people/edit",
  ANAGRAPHICS_PEOPLE_LIST = "/v1/anagraphics/people/list",
  ANAGRAPHICS_PEOPLE_VIEW = "/v1/anagraphics/people/view",
  ANAGRAPHICS_PEOPLE_GUEST_EDIT = "v1/anagraphics/people/guest/edit",
  ANAGRAPHICS_PHOTO_EDIT = "/v1/anagraphics/photo/edit",
  // SESSIONS
  SESSIONS_ADD = "/v1/sessions/add",
  SESSIONS_EDIT = "/v1/sessions/edit",
  SESSIONS_LIST = "/v1/sessions/list",
  SESSIONS_VIEW = "/v1/sessions/view",
  SESSIONS_ACTIVE_EDIT = "/v1/sessions/active/edit",
  SESSIONS_ACTIVE_LOUNGE_EDIT = "/v1/sessions/active-lounge/edit",
  SESSIONS_OVERBOOKING_EDIT = "/v1/sessions/overbooking/edit",
  SESSION_ATTENDANCE_EXPORT = "/v1/sessions/attendance/export",
  LOOKUP_SESSION_TYPE = "/v1/lookup/session-type/get",

  // INVITI
  INVITATIONS_ADD_V1 = "/v1/invitations/add",
  INVITATIONS_ADD = "/v2/invitations/add",
  INVITATIONS_EDIT = "/v1/invitations/edit",
  INVITATIONS_LIST = "/v1/invitations/list",

  INVITATIONS_VIEW = "/v1/invitations/view",
  INVITATIONS_GUEST_ADD = "/v1/invitations/guest/add",
  INVITATIONS_GUEST_EDIT = "/v1/invitations/guest/edit",
  INVITATIONS_GUEST_DEL = "/v1/invitations/guest/del",
  INVITATIONS_GUEST_VIEW = "/v1/invitations/guest/view",
  INVITATIONS_GUEST_LIST = "/v1/invitations/guest/list",
  INVITATIONS_SESSION_VIEW = "/v1/invitations/session/view",
  INVITATIONS_CONFIRM = "v1/invitations/confirm",
  INVITATIONS_PUBLIC_VIEW = "/v1/invitations/public/view",
  INVITATIONS_MAIL_RESEND = "/v1/invitations/mail/resend",
  INVITATIONS_CF_LIST = "/v1/invitations/cf/list",
  INVITATIONS_EAN_14 = "/v1/invitations/ean14/export",
  INVITATION_SESSION_SEATS_DEL = "v1/invitation/session/seat/del",
  INVITATION_NOTES_EDIT = "v1/invitations/notes/edit",
  INVITATION_INTERNAL_NOTES_EDIT = "v1/invitations/internal-notes/edit",
  INVITATION_SESSION_ADD_LIST = "v1/invitation/session/add/list",
  INVITATION_SESSION_ADD = "v1/invitation/session/add",
  INVITATION_SESSION_LOUNGE_EDIT = "v1/sessions/lounge/edit",
  INVITATION_COMMUNICATION_LIST = "/v1/system/communications-activity/get",
  INVITATION_COMMUNICATION_LIST_FILTRED = "v1/system/communications-activity-filtrata/get",

  //nuove funzionalita invito
  INVITATION_CANCEL = "v1/invitations/cancel",
  INVITATION_REFUSE = "v1/invitations/refuse",
  INVITATION_RESTORE = "v1/invitations/restore",
  INVITATION_DEL = "/v1/invitations/delete",
  INVITATION_ADD_CONFIRM = "/v1/invitations/add-confirm", //SEDUTA RAPIDA
  // SEATS
  SEATS_ADD = "/v1/seats/add",
  SEATS_EDIT = "/v1/seats/del",
  SEATS_UPDATE = "/v1/seats/update",
  SEATS_DEL = "/v1/seats/del",
  SEATS_LIST = "/v1/seats/event-year/view",
  SEATS_SESSION_VIEW = "/v1/seats/session/view",
  SEATS_SESSION_PRINT_VIEW = "/v1/seats/session/print/view",
  SEATS_SESSION_PRINT_CLICK = "/v1/seats/print/click",
  // HELPDESK
  HELPDESK_REQUEST_ADD = "/v1/helpdesk/request/add",
  //esportazioni
  INVITATION_EXPORT_V1 = "/v1/invitations/export",
  INVITATION_EXPORT_V2 = "/v2/invitations/export",
  //ticketing
  TICKETING_INVITATION_LIST = "/v1/ticketing/invitation/list",
  TICKETING_INVITATION_VIEW = "/v1/ticketing/invitation/view",
  TIKCETING_PAYMENT_CONFIRM = "/v1/ticketing/payment/confirm",
  TIKCETING_PAYMENT_INVITATION_CONFIRM = "/v1/ticketing/invitation/confirm",
  INVITATION_PUBLIC_CONFIRM = "/v1/invitations/public/confirm",
  // Entrata/uscita/reset posti in tribuna
  USERS_ENTRATA = "/v1/users",
  USERS_USCITA = "/v1/users/exit",
  USERS_RESET = "/v1/users/reset",
  // configurazioni
  CONFIGURATION_EVENT_YEAR_VIEW = "/v1/configuration/event-year/view",
  // LogCrionet
  ANAGRAPHICS_PEOPLE_CRIONET_EXPORT_LOG_LIST = "v1/anagraphics/people/crionet-export/log/list",
}
export enum STATUS_INVITI {
  NUOVO = 1,
  INVIATO = 2,
  BOZZA = 3,
  ACCETTATO = 4,
  CONFERMATO = 5,
  RIFIUTATO = 6,
  PAGAMENTI = 7,
  CONSIGLIERE_FEDERALE = 8,
  ANNULLATO = 9,
}
export enum INVITI_STATUS {
  INVIATO = 2,
  DA_CONFERMARE = 4,
  CONFERMATO = 5,
}
export enum GRUPPI {
  Gruppo = 0,
  // Ospiti = 1,
  // TennisClub = 2,
  // ConsiglieriFederaliPresidentiCR = 3,
  // InvitiGenerici = 4,
  // InvitiPartiti = 5,
  // InvitiPresidentiFSN = 6,
  // ComitatoPerLeFinals = 7,
  // Istituzionali = 8,
  // CRSicilia = 9,
  // CRMarche = 10,
  // CRTrento = 14,
  // CRUmbria = 15,
  // CRLazio13 = 16,
  // CRLazio14 = 17,
  // Cerimoniale = 18,
  // CRFriuli13 = 19,
  // CRCampania15 = 20,
  // CRToscana10 = 21,
  // CRLombardia8 = 22,
  // CRLombardia9 = 23,
  // CRLombardia13 = 24,
  // OspitiSenzaAcc = 25,
  // CRPuglia14 = 26,
  // CRLiguria12 = 27,
  // CRPiemonte12 = 28,
  // CREmilia13 = 29,
  // CRAbruzzo12 = 30,
  // CRSardegna11 = 31,

  //nuovi
  PersonaliGenerici = 1,
  SardegnaPersonali = 2,
  Partiti = 3,
  PresidentiFSN = 4,
  SesConiVarie = 5,
  ISPSponsor = 6,
  PoliticiNonSardegna = 7,
  PoliticiETestateSardegna = 8,
  CF = 9,
  PresidentiComitatiRegionali = 10,
  Emilia19Diurna = 11,
  Marche20Diurna = 12,
  Toscana20Diurna = 13,
  Abruzzo21Diurna = 14,
  Sardegna21Diurna = 15,
  Sicilia21Diurna = 16,
  Umbria21Diurna = 17,
  Basilicata22Diurna = 18,
  Veneto22Serale = 19,
  LazioConAcc = 20,
  LazioSenzaAcc = 21,
  Campania18Diurna = 22,
  DirigenteConAcc = 23,
  DirigenteSenzaAcc = 24,
  Lazio20aConAcc = 25,
  Lazio21aSenzaAcc = 26,
  AccoglienzaOspitiFITP = 27,
  Calabria23 = 28,
}
