// src/composables/useSocket.js

import { ref, onMounted } from "vue";
import { io } from "socket.io-client";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

export default function useSocket() {
  const $toast = useToast();

  const message = ref("Nessun messaggio al momento");
  const cardData = ref(null);
  let socket = null;

  socket = io("http://localhost:3001");

  const openModal = () => {
    console.log("Apri il modal!");
    document.getElementById("RFID_modal_btn").click();
  };

  socket.on("connect", () => {
    message.value = "Connessione WebSocket stabilita!";
    $toast.open({ message: "Lettore ACR122U Connesso" });
    console.log("Socket connesso, id:", socket.id);
  });

  socket.on("cardDetected", (card) => {
    cardData.value = card;
    console.log("Valore letto:", card);
    $toast.open({
      message: "Rilevato tag RFID Numero " + card.number,
      onClick: () => openModal(),
    });
    message.value = "Carta rilevata in tempo reale!";
  });

  socket.on("cardData", (data) => {
    if (data) {
      cardData.value = data;
      message.value = "Ultimo tag recuperato!";
    } else {
      message.value = "Nessun tag disponibile.";
    }
  });

  socket.on("disconnect", () => {
    $toast.open({
      message: "Connessione al lettore ACR122U interrotta ",
      type: "error",
    });
  });

  function requestCardData() {
    if (socket) {
      socket.emit("getLastCard");
    }
  }

  return { requestCardData, cardData, message };
}
