import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

import { msalPlugin } from "@/plugins/msalPlugin";
import {
  msalInstance,
  loginRequest,
  silentTokenRequest,
  msalInstanceReset,
  resetPasswordRequest,
} from "./authConfig";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "@/router/NavigationClient";
import LoaderVue from "@/components/utility/LoaderInsidePage.vue";
import LoadingVue from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

let configurazioni_prese = false;

const logout = () => {
  localStorage.clear();
  msalInstance.logoutRedirect();
};
const timestamp = Date.now();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);

  msalInstance.acquireTokenSilent(silentTokenRequest).then((userInfo) => {
    localStorage.setItem("tokenAdb2c", userInfo.idToken);
  });
}

msalInstance.addEventCallback(async (event: any) => {
  if (
    event.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START &&
    accounts[0].idTokenClaims &&
    typeof accounts[0]?.idTokenClaims?.exp === "number" &&
    timestamp > accounts[0]?.idTokenClaims?.exp * 1000
  ) {
    // logout();
    await msalInstance.acquireTokenSilent(loginRequest).then((userInfo) => {
      localStorage.setItem("tokenAdb2c", userInfo.idToken);
      store.commit("setToken", userInfo.idToken);
      store.commit("setUser", msalInstance.getActiveAccount());
    });
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error.errorMessage.includes("AADB2C90118")) {
      msalInstanceReset.loginRedirect(resetPasswordRequest);
    } else logout();
  }
  if (
    event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
    event.eventType === EventType.SSO_SILENT_FAILURE
  ) {
    logout();
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    await msalInstance
      .acquireTokenSilent(loginRequest)
      .then(async (userInfo: any) => {
        localStorage.setItem("tokenAdb2c", userInfo.idToken);
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("myParam");

        if (!urlParams.size) {
          await store.dispatch("getConfigurazioniEvento");
          configurazioni_prese = true;
        }
      });
  }
});

const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get("myParam");

if (
  !urlParams.size &&
  !configurazioni_prese &&
  localStorage.getItem("tokenAdb2c") != undefined
) {
  store.dispatch("getConfigurazioniEvento");
}

const app = createApp(App);

app.use(store);
app.use(router);
app.use(msalPlugin, msalInstance);
app.use(Vue3ColorPicker);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();
app.use(ElementPlus, { size: "small", zIndex: 3000 });
app.component("LoadingVue", LoadingVue).component("LoaderVue", LoaderVue);

app.mount("#app");
console.log(window);

if ("hid" in navigator) {
  // The WebHID API is supported.
  console.log("The WebHID API is supported");
}
if ("NDEFReader" in window) {
  /* Scan and write NDEF Tags */ console.log("NDEFReader");
}
