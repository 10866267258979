import { requestAppPost } from "@/db/dbManag";
import { API } from "@/enums/costanti";
import { msalInstance } from "@/authConfig";
import router from "../../router";
const getDefaultState = () => {
  return {
    loadedMenu: false,
    statusMenu: 0,
    menu: [],
  };
};

const state = getDefaultState();

const getters = {
  menu: (state) => state.menu,
  loadedMenu: (state) => state.loadedMenu,
  statusMenu: (state) => state.statusMenu,
};
const actions = {
  getMenu: async (storeObj, payload) => {
    storeObj.commit("resetMenu");
    const res = await requestAppPost(API.SYSTEM_MENU_GET, payload).then(
      (res) => {
        if (res.status != 200) {
          msalInstance.logoutRedirect();
        }
        return res;
      }
    );

    storeObj.commit("setMenu", res);
  },
};
const mutations = {
  setMenu: (state, res) => {
    state.statusMenu = res.status;
    state.menu = res.data.results;
    state.loadedMenu = true;

    if (
      router.currentRoute.value.fullPath == "/" ||
      router.currentRoute.value.fullPath == "" ||
      router.currentRoute.value.fullPath.includes("#state=")
    )
      router.push(res.data.results[0].url);
  },
  resetMenu: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
